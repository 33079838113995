import React, { useState, useCallback } from "react"
import { Form, Modal, Button, Alert } from "react-bootstrap"
import { JSON_FETCH_HEADERS } from './constants'
import Success from "./flashSuccess"
import { log } from "./utils"

const emptyForm = {
  camp_name: "",
  camp_parent_company: "",
  url: "",
  phone: "",
  location: "",
  age_or_grade: "",
  aftercare: "",
  beforecare: "",
  cost: "",
  notes: ""
}

const CreateCampModal = (props) => {
  const { newCampName, setNewCampName, campsVersion, setCampsVersion, setSelectedCampId } = props

  const [formData, setFormData] = useState({
    ...emptyForm,
    camp_name: newCampName,
  })
  const [errors, setErrors] = useState([])

  const resetForm = () => {
    setFormData(emptyForm)
    setErrors([])
  }

  const handleClose = () => {
    resetForm()
    setNewCampName("")
  }

  const validateForm = useCallback(() => {
    const errs = [];
    if (formData.camp_name == null) {
      errs.push("Camp must have a name.")
    }

    return errs
  }, [formData])

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setFormData({
      ...formData,
      [name]: value
    })
  }

  const handleSubmitForm = (e) => {
    e.preventDefault()
    const errs = validateForm()
    setErrors(errs)
    if (errs.length > 0) {
      return
    }

    fetch(`/api/admin/create_camp`, {
          method: "post",
          headers: JSON_FETCH_HEADERS,
          body: JSON.stringify(formData)
      })
    .then((response) => response.json())
    .then(data => {
        if ("error" in data) {
          alert(data["error"])
        } else {
          // after submitting successfully, trigger a data refresh to include the new camp
          setCampsVersion(campsVersion + 1)
          setSelectedCampId(parseInt(data["camp_id"], 10))
          handleClose()
        }
    })
    .catch(error => console.error("Create camp error:", error))
  }

  const handleClear = (e) => {
    e.preventDefault()
    resetForm()
  }

  return (
    <>
      <Modal
        show={true}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Create a Camp</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {errors.length > 0 && (
            <Alert key="warning" variant="warning">
              {errors.map((err) => (
                <span>
                  {err}
                  <br />
                </span>
              ))}
            </Alert>
          )}
          <Form>
            <Form.Group className="mb-3" controlId="Camp.Name">
              <Form.Label>Camp Name</Form.Label>
              <Form.Control
                type="text"
                name="camp_name"
                value={formData.camp_name}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="Camp.Parent">
              <Form.Label>Parent Company (optional)</Form.Label>
              <Form.Control
                type="text"
                name="camp_parent_company"
                value={formData.camp_parent_company}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="Camp.Url">
              <Form.Label>URL (optional)</Form.Label>
              <Form.Control
                type="text"
                name="url"
                value={formData.url}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="Camp.Phone">
              <Form.Label>Phone (optional)</Form.Label>
              <Form.Control
                type="text"
                name="phone"
                value={formData.phone}
                placeholder="415-123-4567"
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="Event.Location">
              <Form.Label>Location (optional)</Form.Label>
              <Form.Control
                type="text"
                name="location"
                value={formData.location}
                placeholder="100 Main St., San Francisco, CA 94110"
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="Camp.Age">
              <Form.Label>Camp Age or Grade (optional)</Form.Label>
              <Form.Control
                type="text"
                name="age_or_grade"
                value={formData.age_or_grade}
                onChange={handleInputChange}
              />
            </Form.Group>

            {/* beforecare/aftercare checkboxes go here */}
            <Form.Group className="mb-3" controlId="Camp.Cost">
              <Form.Label>Cost (optional)</Form.Label>
              <Form.Control
                type="text"
                name="cost"
                value={formData.cost}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="Camp.Notes">
              <Form.Label>Notes (optional)</Form.Label>
              <Form.Control
                type="text"
                name="notes"
                value={formData.age_or_grade}
                onChange={handleInputChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="secondary" onClick={handleClear}>
            Clear
          </Button>
          <Button variant="primary" onClick={handleSubmitForm}>
            Create Camp
          </Button>
          {errors ? null: <Success /> }
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default CreateCampModal
