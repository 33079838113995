import { atom } from 'jotai'
import { EMPTY_USER_LOGGED_IN } from "./constants"

/*
    userId: null,
    email: null,
    currentChildId: null,
    children: [{
        child_id
        user_id
        fname
        lname
        full_name
        school
        invite_code
    }],
    childrenCalendars: {
        child_id: [{
            "camp_attendee_id": row.camp_attendee_id,
            "camp_id": row.camp_id,
            "camp_name": row.camp_name,
            "camp_program_id": row.camp_program_id,
            "camp_program_name": row.camp_program_name,
            "full_program": row.full_program,
            "start_date": row.start_date,
            "end_date": row.end_date,
            "registration_status": row.registration_status,
        }]
    },
    friends: [],
    notifications: [],
    isAdmin: false,
*/
export const userStore = atom(EMPTY_USER_LOGGED_IN)
export const accountStore = atom({
    showAddChildModal: false,
    editChild: null,
})

/*
CAMPS OBJECT STRUCTURE
camps[camp_id] = {
    "camp_id": row.camp_id,
    "camp_name": row.camp_name,
    "camp_parent_company": row.camp_parent_company,
    "url": row.url,
    "phone": row.phone,
    "location": row.location,
    "age_or_grade": row.age_or_grade,
    "aftercare": row.aftercare,
    "beforecare": row.beforecare,
    "cost": row.cost,
    "notes": row.notes,
    "programs": [{
        "camp_program_id": row.camp_program_id,
        "camp_program_name": row.camp_program_name,
        "start_date": row.start_date.isoformat(),
        "end_date": row.end_date.isoformat(),
    }]
}
*/
export const campsAtom = atom([])
export const campsVersionAtom = atom(1)

export const schoolsAtom = atom([])
export const schoolsVersionAtom = atom(1)

// friendCalendars[child_id] = {
//     "child_id": child.child_id,
//     "fname": child.fname,
//     "lname": child.lname,
//     "full_name": child.full_name,
//     "school_id": child.school_id,
//     "friend_programs": friend_calendars[child.child_id],
//     "show_schedule": True
// }
export const friendCalendarsAtom = atom({})
export const showFriendIdsAtom = atom([])

export const cacheFriendCalendarsAtom = atom({})