import React from 'react'
import PropTypes from 'prop-types'
import { displayDateRange } from "./utils"
import dayjs from 'dayjs'

const CustomAgenda = ({ accessors, localizer, length, date, events, onSelectEvent }) => {
  const renderEvents = (events) => {
    events.sort((a, b) => new Date(a.start_date) - new Date(b.start_date))
    let lastDateRange = ''
    return events.map((event, idx) => {
      const currDateRange = displayDateRange(event.start_date, event.end_date)
      const showDateRange = lastDateRange === currDateRange ? false : true;
      lastDateRange = currDateRange
      return (
        <div key={idx}>
          {showDateRange && (
            <div className="date-wrap">
              {currDateRange}
              <div className="line" />
            </div>
          )}
          <div
            className="agenda-event"
          >
            <div className="agenda-event-title" onClick={() => onSelectEvent(event)}>{accessors.title(event)}</div>
            {timeRangeLabel(event)}
            <div className="agenda-event-details">{event.desc}</div>
          </div>
        </div>
      )
    })
  }

  const timeRangeLabel = (event) => {
    const end = accessors.end(event)
    const start = accessors.start(event)

    if (!accessors.allDay(event)) {
      if (dayjs(start).day() === dayjs(end).day()) {
        const timePeriod = `${dayjs(start).format('h:mma')} – ${dayjs(
          end
        ).format('h:mma')}`
        return (<div className="agenda-event-time">{timePeriod}</div>)
      } else {
        const startDate = dayjs(start).format('DD-MM YYYY, h:mma')
        const endDate = dayjs(end).format('DD-MM YYYY, h:mma')
        return (<div className="agenda-event-time">{startDate} – {endDate}</div>)
      }
    }
  }

  return (
    <div className="mt-4">
      <h2>Camp Agenda</h2>
      {events.length !== 0
        ? renderEvents(events) : 'No camps scheduled'
      }
    </div>
  )
}

CustomAgenda.title = (start, { localizer }) => {
  // const end = dates.add(start, 1, 'month')
  // return localizer.format({ start, end }, 'agendaHeaderFormat')
  return 'title'
}

CustomAgenda.navigate = (date, action) => {
  return null
}

// CustomAgenda.title = (start, { localizer }) => {
//   const end = dates.add(start, 1, 'month')
//   return localizer.format({ start, end }, 'agendaHeaderFormat')
// }

// CustomAgenda.navigate = (date, action) => {
//   const sDate = dayjs(date).startOf('month').toDate()
//   switch (action) {
//     case 'PREV':
//       return dates.add(sDate, -1, 'month')
//     case 'NEXT':
//       return dates.add(sDate, 1, 'month')
//     default:
//       return date
//   }
// }

CustomAgenda.propTypes = {
  events: PropTypes.array,
  date: PropTypes.instanceOf(Date),
  length: PropTypes.number,
  selected: PropTypes.object,
  accessors: PropTypes.object.isRequired,
  components: PropTypes.object.isRequired,
  getters: PropTypes.object.isRequired,
  localizer: PropTypes.object.isRequired,
}

export default CustomAgenda