import React from 'react'
import { Container, Col, Row } from "react-bootstrap"
import ReportErrorModal from './reportErrorModal'

function BottomBar() {
   
    return (
        <Container>
            <Col>
                <Row>
                    <div className="bottom-bar">
                        <ReportErrorModal />
                    </div>
                </Row>
            </Col>
        </Container>
    )
}

export default BottomBar
