export const JSON_FETCH_HEADERS = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
}

export const BADGE_COLORS = [
    'red', 'orange', 'purple', 'brown', 'black'
]

/*
userId: null,
email: null,
currentChildId: null,
children: [{
    child_id
    user_id
    fname
    lname
    full_name
    school
    invite_code
}],
childrenCalendars: {
    child_id: [{
        "camp_attendee_id": row.camp_attendee_id,
        "camp_id": row.camp_id,
        "camp_name": row.camp_name,
        "camp_program_id": row.camp_program_id,
        "camp_program_name": row.camp_program_name,
        "program_start": row.program_start.isoformat(),
        "program_end": row.program_end.isoformat(),
        "schedule_id": row.schedule_id,
        "schedule": row.schedule,
        "full_program": row.full_program,
        "start_date": row.start_date.isoformat(),
        "end_date": row.end_date.isoformat(),
        "registration_status": row.registration_status,
   }]
}
*/
export const EMPTY_USER_LOGGED_IN = {
    userId: null,
    email: null,
    currentChildId: null,
    children: [],
    childrenCalendars: [],
    friends: [],
    notifications: [],
    isAdmin: false,
}

export const EMPTY_SELECTED_PROGRAM = {
    id: null,
    camp_attendee_id: null,
    child_id: null,
    camp_id: null,
    camp_program_id: null,
    title: null,
    campName: null,
    campProgramName: null,
    programStart: null,
    programEnd: null,
    schedule_id: null,
    schedule: null,
    fullProgram: true,
    start_date: null,
    end_date: null,
    start: null,
    end: null,
    allDay: true,
    details: null,
    friends: [],
    registrationStatus: "none",
}