import React, { useState, useCallback, useEffect } from "react"
import { Form, Modal, Button, Alert } from "react-bootstrap"
import { JSON_FETCH_HEADERS } from './constants'

const emptyForm = {
  from_page: window.location.href,
  error_msg: "",
}

const ReportErrorModal = () => {
  const [show, setShow] = useState(false)
  const [formData, setFormData] = useState(emptyForm)
  const [errors, setErrors] = useState([])

  const resetForm = () => {
    setFormData(emptyForm)
    setErrors([])
  }
  const handleClose = () => setShow(false)
  const handleShow = () => {
    resetForm()
    setShow(true)
  }

  const validateForm = useCallback(() => {
    const errs = [];
    if (formData.error_msg === "") {
      errs.push("Error report must have a message.")
    }

    return errs
  }, [formData])

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setFormData({
      ...formData,
      [name]: value
    })
  }

  const handleSubmitForm = (e) => {
    e.preventDefault()
    const errs = validateForm()
    setErrors(errs)
    if (errs.length > 0) {
      return
    }

  fetch(`/api/report_error`, {
      method: "post",
      headers: JSON_FETCH_HEADERS,
      body: JSON.stringify(formData)
    })
    .then((response) => response.json())
    .then(data => {
        if ("error" in data) {
            alert(data["error"])
        } else {
            resetForm()
            handleClose()
        }
    })
    .catch(error => console.error("report error error:", error))
  }

  return (
    <>
      <a className="report-error-link" onClick={handleShow}>Report an error</a>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Report an Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {errors.length > 0 && (
            <Alert key="warning" variant="warning">
              {errors.map((err) => (
                <span>
                  {err}
                  <br />
                </span>
              ))}
            </Alert>
          )}
          <Form>
            <Form.Group className="mb-3" controlId="child_name">
              <Form.Label>Please describe your issue:</Form.Label>
              <Form.Control
                as="textarea"
                rows={3} 
                name="error_msg"
                value={formData.error_msg}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmitForm}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default ReportErrorModal
