import React, { useState, useCallback } from "react"
import { Form, Modal, Button, Alert } from "react-bootstrap"
import { makeIdNameOptions } from "../utils"
import { JSON_FETCH_HEADERS } from '../constants'

const emptyForm = {
  camp_id: "",
  camp_program_name: "",
  start_date: "",
  end_date: "",
}

const CreateCampProgramModal = (props) => {
  const { camps, campsVersion, setCampsVersion } = props
  const [show, setShow] = useState(false)
  const [formData, setFormData] = useState(emptyForm)
  const [errors, setErrors] = useState([])

  const campOptions = makeIdNameOptions(Object.values(camps), "camp_id", "camp_name")

  const resetForm = () => {
    setFormData(emptyForm)
    setErrors([])
  }

  const handleClose = () => setShow(false)
  const handleShow = () => {
    resetForm()
    setShow(true)
  }

  const validateForm = useCallback(() => {
    const errs = [];
    if (formData.camp_id === "") {
        errs.push("Camp must be selected.")
    }
    if (formData.start_date === "") {
        errs.push("Camp must have a start date.");
    }
    if (formData.end_date === "") {
        errs.push("Camp must have an end date.");
    }

    return errs
  }, [formData])

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setFormData({
      ...formData,
      [name]: value
    })
  }

  const handleSubmitForm = (e) => {
    e.preventDefault()
    const errs = validateForm()
    setErrors(errs)
    if (errs.length > 0) {
      return
    }

    fetch(`/api/admin/create_camp_program`, {
      method: "post",
      headers: JSON_FETCH_HEADERS,
      body: JSON.stringify(formData)
    })
    .then((response) => response.json())
    .then(data => {
      if ("error" in data) {
        alert(data["error"])
      } else {
        // after submitting successfully, trigger a data refresh to include the new camp program
        setCampsVersion(campsVersion + 1)
      }
    })
    .catch(error => console.error("Create camp program error:", error))

    // keep the current camp selected for convenience
    setFormData({
      camp_id: formData.camp_id,
      camp_program_name: "",
      start_date: "",
      end_date: "",
    })
  }

  return (
    <>
      <Button variant="secondary" onClick={handleShow}>
        Create a Camp Program
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Create a Camp Program</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {errors.length > 0 && (
            <Alert key="warning" variant="warning">
              {errors.map((err) => (
                <span>
                  {err}
                  <br />
                </span>
              ))}
            </Alert>
          )}
          <Form>
            <Form.Group className="mb-3" controlId="CampProgram.Camp">
              <Form.Label>Camp</Form.Label>
              <Form.Select
                name="camp_id"
                value={formData.camp_id}
                onChange={handleInputChange}
                required
              >
                <option value="">-</option>
                {campOptions}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" controlId="CampProgram.Name">
              <Form.Label>Camp Program Name</Form.Label>
              <Form.Control
                type="text"
                name="camp_program_name"
                value={formData.camp_program_name}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="CampProgram.StartDate">
              <Form.Label>Camp Start Date</Form.Label>
              <Form.Control
                type="date"
                name="start_date"
                value={formData.start_date}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="CampProgram.EndDate">
              <Form.Label>Camp End Date</Form.Label>
              <Form.Control
                type="date"
                name="end_date"
                value={formData.end_date}
                onChange={handleInputChange}
                required
              />
            </Form.Group>

          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmitForm}>
            Create Program
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default CreateCampProgramModal
