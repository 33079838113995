import React, { useState } from "react"
import { Form, Modal, Button, Alert } from "react-bootstrap"
import ProgramDates from "./programDates"
import CampModal from "./campModal"
import { formatDate, log } from "./utils"

const MyRegistrationStatus = (props) => {
  const { status, setMyProgramStatus } = props

  return (
    <Form.Group className="mb-3" controlId="Camp.Status">
      <Form.Label className="program-form-label">My Registration Status</Form.Label>

      <Form.Select
        value={status}
        onChange={setMyProgramStatus}
        required
      >
        <option value="">- Choose your current status -</option>
        <option value="none">Not Attending</option>
        <option value="registered">Registered</option>
        <option value="interested">Interested</option>
        <option value="waitlisted">Waitlisted</option>
      </Form.Select>
    </Form.Group>
  )
}

const FriendsRegistered = (props) => {
  const { friends, friendLookup } = props
  if (friends.length === 0) {
    return null
  }
  return (
    <div>
      <div className="program-friends-attending">Friends Attending Program:</div>
      <ul>
        {friends.map((friend) => {
          return (<li key={friend.friendId}>
            {friendLookup[friend.friendId].full_name}
            &mdash;
            <i>{friend.registrationStatus}</i>
            &mdash; 
            {formatDate(friend.start_date)} to {formatDate(friend.end_date)}
          </li>)
        })}
      </ul>
    </div>
  )  
}

const ProgramDetailsModal = (props) => {
  const { camp, selectedProgram, setShowSelectedProgram, setMyProgramStart, setMyProgramEnd, setMyProgramStatus, friendLookup, programFriends } = props
  const [state, setState] = useState({
    showCamp: false,
    showCampProgram: false,
  })

  // "camp_id": row.camp_id,
  // "camp_name": row.camp_name,
  // "camp_parent_company": row.camp_parent_company,
  // "url": row.url,
  // "phone": row.phone,
  // "location": row.location,
  // "age_or_grade": row.age_or_grade,
  // "aftercare": row.aftercare,
  // "beforecare": row.beforecare,
  // "cost": row.cost,
  // "notes": row.notes,

  const handleClose = () => setShowSelectedProgram(false) 
  const errors = []

  const handleCampProgram = () => {
    setState({...state, showCampProgram: !state.showCampProgram})
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target
    if (name === 'start_date') {
      setMyProgramStart(e.target.value)
    } else if (name === 'end_date') {
      setMyProgramEnd(e.target.value)
    }
  }

  const handleSetMyProgramStatus = (e) => {
    if (e.target.value) {
      setMyProgramStatus(e.target.value)
    }
  }

  /*
    id: null,
    camp_attendee_id: null,
    child_id: null,
    camp_id: null,
    camp_program_id: null,
    title: null,
    campName: null,
    campProgramName: null,
    programStart: null,
    programEnd: null,
    schedule_id: null,
    schedule: null,
    fullProgram: true,
    start: null,
    end: null,
    allDay: true,
    details: null,
    friends: [],
    registrationStatus: "none",
  */
  return (
    <Modal
      show={true}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>Camp Program Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {errors.length > 0 && (
          <Alert key="warning" variant="warning">
            {errors.map((err) => (
              <span>
                {err}
                <br />
              </span>
            ))}
          </Alert>
        )}

        {state.showCampProgram && (
          // <Alert>Camp Program: {JSON.stringify(camp)}-------{JSON.stringify(selectedProgram)}</Alert>
          <CampModal camp={camp} program={selectedProgram} closeModal={handleCampProgram} />
        )}
        <h3><a className="title-link" onClick={handleCampProgram}>
          {selectedProgram.campName}
          {selectedProgram.campProgramName && (
            <span>: {selectedProgram.campProgramName}</span>
          )}
        </a></h3>

        {/* dates of program */}
        <ProgramDates
          minDate={selectedProgram.programStart}
          maxDate={selectedProgram.programEnd}
          schedule={selectedProgram.schedule}
          startDate={selectedProgram.start_date}
          endDate={selectedProgram.end_date}
          handleInputChange={handleInputChange}
          selectedDates={""}
          updateSelectedDates={()=>{}}
        />

        {/* <div className="program-dates">{formatDate(selectedProgram.start, selectedProgram.allDay)} to {formatDate(selectedProgram.end, selectedProgram.allDay)}</div> */}

        {/* text details of program, if any */}
        {selectedProgram.details && (
          <p>{selectedProgram.details}</p>
        )}

        {/* current child's status dropdown */}
        <MyRegistrationStatus status={selectedProgram.registrationStatus} setMyProgramStatus={handleSetMyProgramStatus} />

        {/* list of any friends attending or interested in this program */}
        <FriendsRegistered friends={selectedProgram.friends ?? []} friendLookup={friendLookup} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>Close</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ProgramDetailsModal
