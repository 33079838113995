import React from "react"
import { Container, Row, Col, Form } from "react-bootstrap"
import { log, displayDateRange, makeWeeks } from "./utils"

const ProgramDates = (props) => {
    // selectedDates and updateSelectedDates are only used for week dropdown, which is disabled for now
    const { minDate, maxDate, schedule, startDate, endDate, handleInputChange, selectedDates, updateSelectedDates } = props
  
    if (schedule === 'make weeks') {
      const fullOption = (
        <option key="full" value={`${minDate}/${maxDate}`}>
          Full session: {displayDateRange(minDate, maxDate)}
        </option>)
      const dateOptions = [fullOption]
  
      const weeks = makeWeeks(minDate, maxDate)
      let weekCnt = 1
      for (const week of weeks) {
        const dates = week.split('/')
        const disp = displayDateRange(dates[0], dates[1])
        dateOptions.push(<option key={week} value={week}>Week {weekCnt}: {disp}</option>)
        weekCnt += 1
      }
  
      return (
        <>
          {dateOptions.length > 0 ? (
            <Form.Group className="mb-3" controlId="Camp.Dates">
              <Form.Label className="program-form-label">Dates</Form.Label>
              <Form.Select
                value={selectedDates}
                onChange={updateSelectedDates}
                required
              >
                {dateOptions}
              </Form.Select>
            </Form.Group>
          ) : null}
        </>
      )
    }
  
    return (
      <Container><Row><Col>
        <Form.Group className="mb-3" controlId="CampProgram.StartDate">
          <Form.Label>Start Date</Form.Label>
          <Form.Control
            type="date"
            name="start_date"
            value={startDate}
            // min={minDate}
            // max={maxDate}
            onChange={handleInputChange}
            required
          />
        </Form.Group>
      </Col>
      <Col>
        <Form.Group className="mb-3" controlId="CampProgram.EndDate">
          <Form.Label>End Date</Form.Label>
          <Form.Control
            type="date"
            name="end_date"
            value={endDate}
            // min={minDate}
            // max={maxDate}
            onChange={handleInputChange}
            required
          />
        </Form.Group>
      </Col></Row></Container>
    )
}

export default ProgramDates