import React, { useState, useCallback, useEffect } from "react"
import { userStore, accountStore, schoolsAtom, schoolsVersionAtom } from "./store"
import { useAtom } from "jotai"
import { Form, Modal, Button, Alert } from "react-bootstrap"
import CreatableSelect from "react-select/creatable"
import { JSON_FETCH_HEADERS } from './constants'
import { log } from "./utils"

const emptyForm = {
    first_name: "",
    last_name: "",
    school_id: null,
    new_school_name: "",
}

const createNewOption = (label) => ({
  label,
  value: -1,
})

const AddChildModal = (props) => {
  // const { currentChildId, setShow, refreshUser, schools, schoolsVersion, setSchoolsVersion } = props
  const { refreshUser } = props
  const [userLoggedIn, setUserLoggedIn] = useAtom(userStore)
  const [accountData, setAccountData] = useAtom(accountStore)
  const [schools, setSchools] = useAtom(schoolsAtom)
  const [schoolsVersion, setSchoolsVersion] = useAtom(schoolsVersionAtom)

  const [formData, setFormData] = useState(emptyForm)
  const [errors, setErrors] = useState([])
  const [schoolOptions, setSchoolOptions] = useState([])

  useEffect(() => {
    const currentSchoolOptions = Object.values(schools).map(school => {
      return {
        label: school.school_name,
        value: school.school_id,
      }
    })
    setSchoolOptions(currentSchoolOptions)
  }, [schools])

  const resetForm = () => {
      setFormData(emptyForm)
      setErrors([])
    }

  const handleClose = () => setAccountData({ ...accountData, showAddChildModal: false })

  const validateForm = useCallback(() => {
      const errs = []
      if (formData.first_name == null) {
          errs.push("Must enter a first name.")
      }
      if (formData.last_name == null) {
          errs.push("Must enter a last name (at least a last initial).")
      }
      return errs
  }, [formData])

  const handleInputChange = (e) => {
      const { name, value } = e.target
      setFormData({
          ...formData,
          [name]: value
      })
  }

  const handleSchoolChange = (option) => {
    setFormData({
      ...formData,
      "school_id": option.value,
      "new_school_name": option.label,
    })
  }

  const handleCreateSchool = (newSchoolName) => {
    // if the label and value are the same we are adding a new school
    const newOption = createNewOption(newSchoolName)
    const newSchoolOptions = [
      ...schoolOptions,
      newOption,
    ]
    setSchoolOptions(newSchoolOptions)

    const newFormData = {
      ...formData,
      "school_id": -1,
      "new_school_name": newSchoolName
    }
    setFormData(newFormData)
  }

  const handleSubmitForm = (e) => {
      e.preventDefault()
      const errs = validateForm()
      setErrors(errs)
      if (errs.length > 0) {
          return
      }

      fetch(`/api/add_child`, {
          method: "post",
          headers: JSON_FETCH_HEADERS,
          body: JSON.stringify(formData)
      })
      .then((response) => response.json())
      .then(data => {
          if ("error" in data) {
              alert(data["error"])
          }
          if (formData.school_id === -1) {
            setSchoolsVersion(schoolsVersion + 1)
          }
          resetForm()
          refreshUser(userLoggedIn.currentChildId)
          handleClose()
      })
      .catch(error => console.error("Add child error:", error))
  }

  return(
    <Modal
      show={true}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Add Your Child</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {errors.length > 0 && (
          <Alert key="warning" variant="warning">
            {errors.map((err) => (
              <span>
                {err}
                <br />
              </span>
            ))}
          </Alert>
        )}
        <p>Add your child. Each child gets a separate calendar tab where they can see the camp schedules of all their friends!</p>
        <Form>
          <Form.Group className="mb-3" controlId="AddChild.first_name">
            <Form.Label>First Name</Form.Label>
            <Form.Control
              type="text"
              name="first_name"
              value={formData.first_name}
              onChange={handleInputChange}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="AddChild.last_name">
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              type="text"
              name="last_name"
              value={formData.last_name}
              onChange={handleInputChange}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="AddChild.school">
            <Form.Label>School (optional)</Form.Label>
            <CreatableSelect
              name="school_id"
              options={schoolOptions}
              value={formData.school_id?.value}
              onChange={handleSchoolChange}
              onCreateOption={handleCreateSchool}
              placeholder="Type school name or select..."
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleSubmitForm}>
            Add Your Child
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default AddChildModal
