import React, { useState } from 'react'
import { FcGoogle } from "react-icons/fc"
import {
  Form,
  Button,
  Card,
} from "react-bootstrap"
import { JSON_FETCH_HEADERS } from './constants'
import { log } from './utils'

function LoginUser(props) {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const handleEmailChange = (event) => {
    setEmail(event.target.value)
  }

  const handlePasswordChange = (event) => {
    setPassword(event.target.value)
  }

  const logUserIn = (e) => {
    e.preventDefault()
    const userDetails = {
      "email": email,
      "password": password
    }

    fetch(`/api/login_user`, {
      method: "post",
      headers: JSON_FETCH_HEADERS,
      body: JSON.stringify(userDetails)
    })
      .then((response) => response.json())
      .then(data => {
        if ("error" in data) {
          alert(data["error"])
        } else {
          const user = {
            userId: data["user_id"],
            email: data["email"],
            currentChildId: data["current_child_id"],
            children: data["children"],
            childrenCalendars: data["children_calendars"],
            friends: data["friends"],
            notifications: data["notifications"],
            isAdmin: data["is_admin"],
          }
          props.setUserLoggedIn(user)
          localStorage.setItem('user', JSON.stringify(user))
          props.onClose()
        }
      })
      .catch(error => alert("Login Error: " + error))
  }

  return (
    <div className="w-100 text-center mb-3">
      <div>
          <Button
            variant="white-outline"
            className="btn btn-outline-dark px-3 mt-4 mb-3"
            onClick={() => { window.location.href = process.env.REACT_APP_BACKEND_URL + "/authorize/google" }}
          >
            <FcGoogle className="mb-1" />&nbsp; <span className="fw-semibold">Sign in with Google</span>
          </Button>
      </div>
      <div class="mx-5 text-start">
      <Form onSubmit={logUserIn}>
        <Form.Group className="mb-3">
            <Form.Label>Email</Form.Label>
          <div class="row d-flex justify-content-center">          
            <Form.Control type="email" onChange={handleEmailChange} required />
          </div>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Password</Form.Label>
          <div class="row d-flex justify-content-center">
            <Form.Control type="password" id="login-password" name="password" onChange={handlePasswordChange} required />
          </div>
        </Form.Group>

        <div className="text-center">
          <Button className="btn btn-primary" type="submit">Login</Button>
        </div>
      </Form>
      </div>
    </div>
  )
}

export default LoginUser