import React, { useEffect, useMemo, useState } from "react"
import { useAtom } from "jotai"
import { userStore, accountStore } from "./store"
import { Tab, Tabs } from "react-bootstrap"
import { log } from "./utils"

const CurrentChildTabs = () => {
    const [userLoggedIn, setUserLoggedIn] = useAtom(userStore)
    const [accountData, setAccountData] = useAtom(accountStore)
    const currentChildId = useMemo(() => userLoggedIn.currentChildId, [userLoggedIn.currentChildId])

    // userLoggedIn.children: [{
    //     child_id
    //     user_id
    //     fname
    //     lname
    //     full_name
    //     school
    //     invite_code
    // }],
    const childIds = useMemo(() => {
        if (!userLoggedIn) return []
        return userLoggedIn.children.map(child => child.child_id)
    }, [userLoggedIn])
    const [key, setKey] = useState("")

    useEffect(() => {
        if (currentChildId) {
            setKey(currentChildId.toString())
        }
    }, [currentChildId])

    const handleTabChange = (childIdStr) => {
        const childId = childIdStr ? parseInt(childIdStr) : null
        if (childId === 0) {
            setAccountData({ ...accountData, showAddChildModal: true })
            // setShowAddChildModal(true)
        } else {
            setKey(childIdStr)
            const user = {
                ...userLoggedIn,
                "currentChildId": childId
            }
            setUserLoggedIn(user)
            localStorage.setItem('user', JSON.stringify(user))
        }
    }

    return (
        <>
            <Tabs
                activeKey={key}
                id="tabs"
                onSelect={(k) => handleTabChange(k)}
            >
                {childIds.length > 0 && childIds.map(childId => {
                    const childFirstName = userLoggedIn.children.filter(child => child.child_id === childId)[0]["fname"]
                    return (
                        <Tab key={childId} eventKey={childId} title={childFirstName} />
                    )
                })}
                {userLoggedIn.currentChildId && (
                    <Tab key="0" eventKey="0" title="add child+" />
                )}
            </Tabs>
        </>
    )
}

export default CurrentChildTabs
