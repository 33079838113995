import React, { useState } from "react"
import { FcGoogle } from "react-icons/fc"
import {
    Form,
    FormControl,
    Button
} from "react-bootstrap"
import { JSON_FETCH_HEADERS } from './constants'

const emptyForm = {
    email: null,
    first_name: null,
    last_name: null,
    location: null,
    password: "",
}

function RegisterUser(props) {
    const { setUserLoggedIn, onClose } = props
    const [formData, setFormData] = useState(emptyForm)

    const handleInputChange = (e) => {
        const { name, value } = e.target
        setFormData({
            ...formData,
            [name]: value
        })
    }


    const createNewUser = (e) => {
        e.preventDefault()
        // const errs = validateForm()
        // setErrors(errs)
        // if (errs.length > 0) {
        //     return
        // }

        fetch(`/api/register_user`, {
            method: "post",
            headers: JSON_FETCH_HEADERS,
            body: JSON.stringify(formData)
        })
            .then((response) => response.json())
            .then(data => {
                if ("error" in data) {
                    alert(data["error"])
                } else {
                    const user = {
                        userId: data["user_id"],
                        email: data["email"],
                        currentChildId: null,
                        children: [],
                        childrenCalendars: [],
                        friends: data["friends"],
                        notifications: data["notifications"],
                        isAdmin: false,
                    }
                    setUserLoggedIn(user)
                    localStorage.setItem('user', JSON.stringify(user))
                    onClose()
                }
            })
            .catch(error => alert("Login Error: " + error))
    }

    return (
      <div className="w-100 text-center mb-3">
        <div>
            <Button
              variant="white-outline"
              className="btn btn-outline-dark px-3 mt-4 mb-3"
              onClick={() => { window.location.href = process.env.REACT_APP_BACKEND_URL + "/authorize/google" }}
            >
              <FcGoogle className="mb-1" />&nbsp; <span className="fw-semibold">Sign up with Google</span>
            </Button>
        </div>
        <div class="mx-5 text-start">
            <Form onSubmit={createNewUser}>
                <Form.Group className="mb-3">
                    <Form.Label>Email</Form.Label>
                    <div class="row d-flex justify-content-center">          
                        <Form.Control
                            type="email"
                            id="register-email"
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Label>Parent/Guardian First Name</Form.Label>
                    <div class="row d-flex justify-content-center">          
                        <Form.Control
                            type="text"
                            id="register-firstname"
                            name="first_name"
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Label>Parent/Guardian Last Name</Form.Label>
                    <div class="row d-flex justify-content-center">          
                        <Form.Control
                            type="text"
                            id="register-lastname"
                            name="last_name"
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Label>City (optional)</Form.Label>
                    <div class="row d-flex justify-content-center">          
                        <Form.Control
                            type="text"
                            id="register-location"
                            name="location"
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Label>Password</Form.Label>
                    <div class="row d-flex justify-content-center">          
                        <Form.Control
                            type="password"
                            id="register-password"
                            name="password"
                            onChange={handleInputChange}
                            isInvalid={formData.password && formData.password.length < 8}
                            required
                        />
                        <Form.Control.Feedback type="invalid">
                            Password must be at least 8 characters.
                        </Form.Control.Feedback>
                    </div>
                </Form.Group>

                <div className="text-center">
                    <Button className="btn btn-primary" type="submit">Register</Button>
                </div>
            </Form>
        </div>
      </div>
    )
}


export default RegisterUser