import { JSON_FETCH_HEADERS } from './constants'

const DEBUG = true

const getObjName = (obj) => {
    return Object.keys(obj)[0]
}

export const log = (obj, objNameOverride=null) => {
    if (DEBUG) {
        const objName = objNameOverride ? objNameOverride : getObjName(obj) + ":"
        console.log(objName, JSON.stringify(Object.values(obj)[0], null, 4))
    }
}

// people = [{ id: 1, name: "John"}, { id: 2, name: "Sue"}]

// mapObject(people, p => str(p.id))
// { "1": { id: 1, name: "John"}, "2": { id: 2, name: "Sue"} }

// mapObject(people, p => p.name.toLowerCase(), p => p.id)
// { "john": 1, "sue", 2}
export const mapObject = (arr, mapKey, mapValue = i => i) => Object.fromEntries(
    arr.map((el, i, arr) => [mapKey(el, i, arr), mapValue(el, i, arr)])
)

export const makeIdNameOptions = (idNames, idLabel="id", nameLabel="name", defaultOption=null) => {
    if (!Array.isArray(idNames)) { return }
    const options = idNames.map((idName) => (
        <option key={idName[idLabel]} value={idName[idLabel]}>{idName[nameLabel]}</option>
    ))
    if (defaultOption) {
        return [defaultOption, ...options]
    }
    return options
}

export const makeLookup = (objArray, idLabel="id") => {
    return Object.fromEntries(objArray.map((obj) => [obj[idLabel], obj]))
}

export const makeLookupArray = (objArray, idLabel="id") => {
    const lookup = {}
    for (const obj of objArray) {
        lookup[obj[idLabel]] ? lookup[obj[idLabel]].push(obj) : lookup[obj[idLabel]] = [obj]
    }
    return lookup
}

/****************
 * DATE FUNCTIONS
 ************** */
export const ISOFormatToDate = (sqlDate) => {
    const [Y, M, D] = sqlDate.split('-')
    return new Date(parseInt(Y, 10), parseInt(M, 10)-1, parseInt(D, 10))
}

export const formatDate = (dateVal, isAllDay=true) => {
    const dateObj = typeof dateVal === "object" ? dateVal : new Date(dateVal)
    // TODO: handle programs with posted hours
    // const dateOptions = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' }
    const dateOptions = { year: 'numeric', month: 'long', day: 'numeric' }
    return dateObj.toLocaleDateString('en-us', dateOptions)
}

export const formatISODate = (dateVal) => {
    // const dateOptions = { year: 'numeric', month: '2-digit', day: '2-digit' }
    // return dateVal.toLocaleDateString('en-us', dateOptions)
    return dateVal.toISOString().slice(0, 10)
}

export const displayDateRange = (startDate, endDate, showYear=false) => {
    const startDateParts = startDate.split("-")
    const endDateParts = endDate.split("-")

    const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
    // const monthShortNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    const startMonth = monthNames[parseInt(startDateParts[1], 10) - 1]
    const startDay = parseInt(startDateParts[2])
    const endMonth = monthNames[parseInt(endDateParts[1], 10) - 1]
    const endDay = parseInt(endDateParts[2])

    let range = `${startMonth} ${startDay}`
    if (startDateParts[0] !== endDateParts[0] || showYear) {
        range += `, ${startDateParts[0]}`
    }
    range += ` - ${endMonth} ${endDay}`

    if (startDateParts[0] !== endDateParts[0] || showYear) {
        range += `, ${endDateParts[0]}`
    }
    return range
}

export const fetchJson = (url, method, params) => {
    let options = {
        method,
        headers: JSON_FETCH_HEADERS,
    }
    let endpoint = url
    if (method === 'get' || method === 'GET') {
        endpoint += '?' + ( new URLSearchParams( params ) ).toString()
    } else {
        options.body = JSON.stringify( params )
    }
    return fetch(endpoint, options).then( response => response.json() )
}

export const addDays = (oldDate, daysToAdd) => {
    // let result = new Date(oldDate)
    // const newDate = result.setDate(result.getDate() + daysToAdd)
    const newDate = new Date(oldDate.getFullYear(), oldDate.getMonth(), oldDate.getDate() + daysToAdd)
    return newDate
}

export const makeWeeks = (startDate, endDate) => {
    const sd = ISOFormatToDate(startDate)
    const ed = ISOFormatToDate(endDate)
    const weeks = []
    
    let notEnd = true
    let d = new Date(sd.valueOf())
    while (notEnd) {
        const weekStart = formatISODate(d)
        const friday = addDays(d, 4)
        const weekEnd = formatISODate(friday)
        if (ed < friday) { break }
        weeks.push(`${weekStart}/${weekEnd}`)
        const newD = addDays(d, 7)
        if (ed < newD) { break }
        d = newD
    }
    return weeks
}

// let cache = {};
// const request = ( url, params = {}, method = 'GET' ) => {
//     // Quick return from cache.
//     let cacheKey = JSON.stringify( { url, params, method } );
//     if ( cache[ cacheKey ] ) {
//         return cache[ cacheKey ];
//     }    let options = {
//         method
//     };
//     if ( 'GET' === method ) {
//         url += '?' + ( new URLSearchParams( params ) ).toString();
//     } else {
//         options.body = JSON.stringify( params );
//     }
    
//     const result = fetch( url, options ).then( response => response.json() );
//     cache[ cacheKey ] = result;    return result;
// };