import React, { useState, useCallback, useEffect, useMemo } from "react"
import { useAtom } from "jotai"
import { userStore, accountStore, schoolsAtom, schoolsVersionAtom } from "./store"
import { Form, Modal, Button, Alert } from "react-bootstrap"
import CreatableSelect from "react-select/creatable"
import { JSON_FETCH_HEADERS } from './constants'
import { log } from "./utils"


const createNewOption = (label) => ({
    label,
    value: -1,
})

const EditChildModal = (props) => {
    // const { currentChildId, refreshUser, schools, schoolsVersion, setSchoolsVersion } = props
    const { refreshUser } = props
    const [userLoggedIn, setUserLoggedIn] = useAtom(userStore)
    const [accountData, setAccountData] = useAtom(accountStore)
    const [schools, setSchools] = useAtom(schoolsAtom)
    const [schoolsVersion, setSchoolsVersion] = useAtom(schoolsVersionAtom)

    const child = useMemo(() => accountData.editChild, [accountData.editChild])

    const [schoolOptions, setSchoolOptions] = useState([])
    const [currSchoolOption, setCurrSchoolOption] = useState(null)
    const [currFormData, setCurrFormData] = useState({
        "child_id": child.child_id,
        fname: child.fname,
        lname: child.lname,
        school_id: child.school_id,
        new_school_name: "",
    })
    const [errors, setErrors] = useState([])

    useEffect(() => {
        const currentSchoolOptions = Object.values(schools).map(school => {
          return {
            label: school.school_name,
            value: school.school_id,
          }
        })
        setSchoolOptions(currentSchoolOptions)
    }, [schools])
  
    useEffect(() => {
        const newSchoolOption = schoolOptions.find(s => s.value === currFormData?.school_id) ?? null
        if (newSchoolOption !== currSchoolOption) {
            setCurrSchoolOption(newSchoolOption)
        }
    }, [schoolOptions, currFormData])

    const handleClose = () => setAccountData({...accountData, editChild: null })

    const validateForm = useCallback(() => {
        const errs = []
        if (currFormData.fname == null) {
            errs.push("Must enter a first name.")
        }
        if (currFormData.lname == null) {
            errs.push("Must enter a last name (at least a last initial).")
        }
        return errs
    }, [currFormData])


    const handleInputChange = (e) => {
        const { name, value } = e.target
        setCurrFormData({
            ...currFormData,
            [name]: value
        })
    }

    const handleSchoolChange = (option) => {
        setCurrFormData({
          ...currFormData,
          "school_id": option.value,
          "new_school_name": option.label,
        })
    }

    const handleCreateSchool = (newSchoolName) => {
        // if the label and value are the same we are adding a new school
        const newOption = createNewOption(newSchoolName)
        const newSchoolOptions = [
          ...schoolOptions,
          newOption,
        ]
        setSchoolOptions(newSchoolOptions)
  
        const newFormData = {
          ...currFormData,
          "school_id": -1,
          "new_school_name": newSchoolName
        }
        setCurrFormData(newFormData)
    }

    const handleSubmitForm = useCallback((e) => {
        e.preventDefault()
        const errs = validateForm()
        setErrors(errs)
        if (errs.length > 0) {
            return
        }

        fetch(`/api/update_child`, {
            method: "post",
            headers: JSON_FETCH_HEADERS,
            body: JSON.stringify(currFormData)
        })
        .then((response) => response.json())
        .then(data => {
            if ("error" in data) {
                alert(data["error"])
            }
            if (currFormData.school_id === -1) {
              setSchoolsVersion(schoolsVersion + 1)
            }
            handleClose()
            refreshUser(userLoggedIn.currentChildId)
        })
        .catch(error => console.error("Update child error:", error))
    }, [currFormData])

    return(
      <Modal
        show={true}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Child Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {errors.length > 0 && (
            <Alert key="warning" variant="warning">
              {errors.map((err) => (
                <span>
                  {err}
                  <br />
                </span>
              ))}
            </Alert>
          )}
        <Form>
            <Form.Group className="mb-3" controlId="UpdateChild.fname">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                    type="text"
                    name="fname"
                    value={currFormData.fname}
                    onChange={handleInputChange}
                    required
                />
            </Form.Group>
            <Form.Group className="mb-3" controlId="UpdateChild.lname">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                    type="text"
                    name="lname"
                    value={currFormData.lname}
                    onChange={handleInputChange}
                    required
                />
            </Form.Group>
            <Form.Group className="mb-3" controlId="UpdateChild.school">
                <Form.Label>School (optional)</Form.Label>
                <CreatableSelect
                    name="school_id"
                    options={schoolOptions}
                    value={currSchoolOption}
                    onChange={handleSchoolChange}
                    onCreateOption={handleCreateSchool}
                    placeholder="Type school name or select..."
                />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleSubmitForm}>
              Update
          </Button>
        </Modal.Footer>
      </Modal>
    )
}

export default EditChildModal
