import React from "react"
import { Modal, Button } from "react-bootstrap"
import { formatDate } from "./utils"

const CampModal = (props) => {
  const { camp, closeModal } = props

  const handleClose = () => closeModal()

  return (
    <Modal
      show={true}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>Camp and Program Description</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h3>{camp.camp_name}</h3>
        {camp.url && (<p><a href={camp.url} target="_blank">{camp.url}</a></p>)}
        {camp.phone && (<p>{camp.phone}</p>)}
        {camp.age_or_grade && (<p>Age / Grade Range: {camp.age_or_grade}</p>)}
        {camp.cost && (<p>{camp.cost}</p>)}
        {camp.location && (<p>Location: {camp.location}</p>)}
        {camp.details && (<p>{camp.details}</p>)}

        <h3>Active / Upcoming Programs</h3>
        {camp.programs.map(program => (
            <>
                <h4>{program.camp_program_name}</h4>
                {(program.start_date || program.end_date) && (
                    <p>{formatDate(program.start_date)} - {formatDate(program.end_date)}</p>
                )}
                {program.schedule && (<p>Weekly schedule: {program.schedule}</p>)}
                {program.details && (<p>{program.details}</p>)}
                <hr />
            </>
        ))}

      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>Close</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default CampModal
