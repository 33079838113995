import React from "react"
import { useAtom } from "jotai"
import { userStore } from "./store"
import { NavDropdown } from "react-bootstrap"
import { useNavigate } from 'react-router-dom'

const AccountDropdown = (props) => {
    const { logoutUser, notificationCount } = props
    const navigate = useNavigate()
    const [userLoggedIn, setUserLoggedIn] = useAtom(userStore)

    const notificationBadge = notificationCount > 0 ?
        (<div className="notifications">({notificationCount})</div>)
        : null

    return (
        <NavDropdown title={<span>{userLoggedIn.email}{notificationBadge}</span>} id="basic-nav-dropdown">
            <NavDropdown.Item onClick={() => navigate("/")}>Home</NavDropdown.Item>
            <NavDropdown.Item onClick={() => navigate("/account")}>My Account</NavDropdown.Item>
            <NavDropdown.Item onClick={() => navigate("/friends")}>Friends{notificationBadge}</NavDropdown.Item>
            <NavDropdown.Item><hr className="dropdown-divider" /></NavDropdown.Item>
            <NavDropdown.Item onClick={logoutUser}>Logout</NavDropdown.Item>
        </NavDropdown>
    )
}

export default AccountDropdown
