import React from 'react'
import { Modal, Container, Row, Col, Button } from "react-bootstrap"
import LoginUser from "./loginUser"
import RegisterUser from "./registerUser"

function RegisterOrLogin(props) {
    const [show, setShow] = React.useState(false)
    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true)
    const [showRegister, setShowRegister] = React.useState(false)

    return (
        <>
            <Button size="sm" className="float-right" variant="outline-dark" onClick={handleShow}>
                Login
            </Button>

            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                size="lg"
            >
                <Modal.Header closeButton>
                <Modal.Title>{showRegister ? "Create an account" : "Login to your account"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Col>
                            <Row>
                                {showRegister ? (
                                    <div className="text-center">
                                        Already have an account?
                                        <Button className="m-0 pt-0 pb-1" variant="link" onClick={() => setShowRegister(!showRegister)}>
                                            Login
                                        </Button>
                                    </div>
                                ) : (
                                    <div className="text-center">
                                        Don't have an account?
                                        <Button className="m-0 pt-0 pb-1" variant="link" onClick={() => setShowRegister(!showRegister)}>
                                            Sign up
                                        </Button>
                                    </div>
                                )}
                            </Row>
                            <Row>
                                {showRegister ? (
                                    <RegisterUser
                                        setUserLoggedIn={props.setUserLoggedIn}
                                        onClose={handleClose}
                                    />
                                ) : (
                                    <LoginUser
                                        setUserLoggedIn={props.setUserLoggedIn}
                                        onClose={handleClose}
                                    />
                                )}
                            </Row>
                        </Col>
                    </Container>
                </Modal.Body>
                {/* <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Close</Button>
                </Modal.Footer> */}
            </Modal>
        </>
    )
}

export default RegisterOrLogin