import { useAtom } from "jotai"
import { userStore, friendCalendarsAtom, showFriendIdsAtom, accountStore } from "./store"
import { Button } from "react-bootstrap"
import FriendCalendars from "./friendCalendars"
import FindFriendsModal from "./findFriendsModal"
import InviteFriendButton from "./inviteFriendButton"

const LeftSidebar = (props) => {
    const { refreshUser } = props
    const [userLoggedIn, setUserLoggedIn] = useAtom(userStore)
    const [accountData, setAccountData] = useAtom(accountStore)
    const [friendCalendars, setFriendCalendars] = useAtom(friendCalendarsAtom)
    const [showFriendIds, setShowFriendIds] = useAtom(showFriendIdsAtom)

    const openAddChildModal = () => {
        setAccountData({ ...accountData, showAddChildModal: true })
    }

    return (
        <div>
            {userLoggedIn.currentChildId ? (
                <>
                    <FindFriendsModal refreshUser={refreshUser} />
                    {userLoggedIn && userLoggedIn.currentChildId && (
                        <InviteFriendButton childId={userLoggedIn.currentChildId} />
                    )}
                    <FriendCalendars
                        friendCalendars={friendCalendars}
                        setFriendCalendars={setFriendCalendars}
                        setShowFriendIds={setShowFriendIds}
                    />
                </>
            ) : (
                <div className="text-center">
                    <Button variant="primary" onClick={openAddChildModal}>
                        Add Your Child
                    </Button>
                </div>
            )}
        </div>
    )
}

export default LeftSidebar