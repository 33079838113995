import React from 'react';
// import FlashMessage from 'react-flash-message';


function Success(){
    // return(
    // // <FlashMessage duration={5000} persistOnHover={true}>
    // // <div className="flashSuccess">Success! </div>
    // // </FlashMessage>
    // )
}

export default Success;