import { useEffect } from "react"
import { useNavigate } from 'react-router-dom'

function Refresh(props) {
    const { refreshUser } = props
    const navigate = useNavigate()

    useEffect(() => {
        refreshUser()
        navigate('/')
    }, [])

    return (<div></div>)
}

export default Refresh