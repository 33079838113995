import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import CreateCampProgramModal from "./createCampProgramModal"
import CreateCampModal from "./createCampModal"


const AdminButtons = (props) => {
    const { camps, campsVersion, setCampsVersion } = props

    return (
        <Container>
            <Row>
                <Col>
                    <CreateCampModal campsVersion={campsVersion} setCampsVersion={setCampsVersion} />
                </Col>
                <Col>
                    <CreateCampProgramModal camps={camps} campsVersion={campsVersion} setCampsVersion={setCampsVersion} />
                </Col>
            </Row>
        </Container>
    )
}

export default AdminButtons