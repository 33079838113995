import React from "react"
import { Form } from "react-bootstrap"
import { log } from "./utils"


const FriendCalendars = (props) => {
    const { friendCalendars, setFriendCalendars, setShowFriendIds } = props

    const handleInputChange = (e) => {
        const { value, checked } = e.target
        const newFriendCalendars = {
            ...friendCalendars,
            [value]: {
                ...friendCalendars[value],
                "show_schedule": checked,
            }
        }
        setFriendCalendars(newFriendCalendars)
        const newShowFriendIds = Object.values(newFriendCalendars).filter(fc => fc.show_schedule).map(fc => fc.child_id)
        setShowFriendIds(newShowFriendIds)
    }

    return (
        <div className="sidebar-friend-calendars">
            <Form>
                <Form.Label className="sidebar-friend-label">Friend Calendars</Form.Label>
                {Object.keys(friendCalendars).map(friendId => {
                    return (
                        <div key={friendId}>
                            <Form.Check.Input
                                id={`check-${friendId}`}
                                type="checkbox"
                                name={friendCalendars[friendId].full_name}
                                value={friendId}
                                checked={friendCalendars[friendId].show_schedule}
                                onChange={handleInputChange}
                            />
                            <Form.Check.Label htmlFor={`check-${friendId}`}>
                                &nbsp; {friendCalendars[friendId].full_name}
                            </Form.Check.Label>
                        </div>
                    )
                })}
            </Form>
        </div>
    )
}

export default FriendCalendars