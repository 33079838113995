import { useEffect, useMemo } from "react"
import { useAtom } from "jotai"
import { userStore, accountStore, campsAtom, campsVersionAtom, schoolsAtom, schoolsVersionAtom, cacheFriendCalendarsAtom } from "./store"
import { Routes, Route, useNavigate } from "react-router-dom"
import Home from "./home"
import TopNavBar from "./topNavBar"
import AddPendingFriend from "./addPendingFriend"
import MyAccount from "./myAccount"
import Friends from "./friends"
import AddChildModal from "./addChildModal"
import EditChildModal from "./editChildModal"
import Refresh from "./refresh"
import BottomBar from "./bottomBar"
import { JSON_FETCH_HEADERS, EMPTY_USER_LOGGED_IN } from './constants'
import { log } from "./utils"

function App() {
  const [userLoggedIn, setUserLoggedIn] = useAtom(userStore)
  const [accountData, setAccountData] = useAtom(accountStore)
  const [camps, setCamps] = useAtom(campsAtom)
  const [campsVersion, setCampsVersion] = useAtom(campsVersionAtom)
  const [schools, setSchools] = useAtom(schoolsAtom)
  const [schoolsVersion, setSchoolsVersion] = useAtom(schoolsVersionAtom)
  const [cacheFriendCalendars, setCacheFriendCalendars] = useAtom(cacheFriendCalendarsAtom)

  const navigate = useNavigate()

  const notificationCount = useMemo(() => {
    return userLoggedIn?.notifications?.filter(
      (notify) => notify.status === "prepending" || notify.status === "requested").length ?? 0
  }, [userLoggedIn])

  // if the user is in localStorage, set the current child to the stored current child
  useEffect(() => {
    const localStorageUserJson = localStorage.getItem("user")
    if (localStorageUserJson) {
      /******* TEMPORARILY UNCOMMENT THESE TWO LINES TO FIX BAD LOCALSTORAGE ******/
      // setUserLoggedIn(EMPTY_USER_LOGGED_IN)
      // localStorage.clear()

      const localStorageUser = JSON.parse(localStorageUserJson)
      refreshUser(localStorageUser?.currentChildId ?? null)
    }
  }, [])

  useEffect(() => {
    // runs initially and whenever a new camp is created
    getAllCamps()
  }, [campsVersion])

  useEffect(() => {
    // runs initially and whenever a new school is created
    getAllSchools()
  }, [schoolsVersion])

  const refreshUser = async (childId = null) => {
    try {
      const response = await fetch('/api/refresh_user', {
        method: 'GET',
        headers: JSON_FETCH_HEADERS,
      })
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      const data = await response.json()
      if (data.error !== undefined) {
        logoutUser(userLoggedIn.userId)
        return
      }
      const user = {
        userId: data["user_id"],
        email: data["email"],
        currentChildId: childId ?? data["current_child_id"],
        children: data["children"],
        childrenCalendars: data["children_calendars"],
        friends: data["friends"],
        notifications: data["notifications"],
        isAdmin: data["is_admin"],
      }
      setUserLoggedIn(user)
      localStorage.setItem('user', JSON.stringify(user))
    }
    catch (error) {
        console.error('refreshUser Error:', error)
    }
  }

  const logoutUser = () => {
    fetch(`/api/logout_user`, {
        method: "post",
        headers: JSON_FETCH_HEADERS,
        body: JSON.stringify({ userId: userLoggedIn.userId })
    })
    .then((response) => response.json())
    .then(data => {
        // if ("error" in data) {
        //     alert(data["error"])
        // }
        setUserLoggedIn(EMPTY_USER_LOGGED_IN)
        localStorage.clear()
        navigate("/")
    })
    .catch(error => alert("Logout Error: " + error))
  }

  const getAllCamps = async () => {
    try {
        const response = await fetch('/api/get_camps', {
          method: 'GET',
          headers: JSON_FETCH_HEADERS,
        })
        if (!response.ok) {
          throw new Error('Network response was not ok')
        }
        const data = await response.json()
        setCamps(data)
    }
    catch (error) {
        console.error('getAllCamps Error:', error)
    }
  }

  const getAllSchools = async () => {
    try {
        const response = await fetch('/api/get_schools', {
          method: 'GET',
          headers: JSON_FETCH_HEADERS,
        })
        if (!response.ok) {
          throw new Error('Network response was not ok')
        }
        const data = await response.json()
        setSchools(data)
    }
    catch (error) {
        console.error('getAllSchools Error:', error)
    }
  }

  return (
    <div className="App">
 
      <TopNavBar logoutUser={logoutUser} notificationCount={notificationCount} />

      <Routes>
        <Route path="/" element={<Home refreshUser={refreshUser} cacheFriendCalendars={cacheFriendCalendars} />} />
        <Route path="/friend/:inviteCode" element={<AddPendingFriend />} />
        <Route path="/account" element={<MyAccount />} />
        <Route path="/friends" element={<Friends refreshUser={refreshUser} />} />
        <Route path="/refresh" element={<Refresh refreshUser={refreshUser} />} />
      </Routes>

      <BottomBar />

      {accountData.showAddChildModal && <AddChildModal refreshUser={refreshUser} />}
      {accountData.editChild && <EditChildModal refreshUser={refreshUser} />}
    </div>
  )
}

export default App
