import React, { useState, useCallback, useMemo, useEffect } from "react"
import { Row, Col, Form, Modal, Button, Alert } from "react-bootstrap"
import CreatableSelect from "react-select/creatable"
import { JSON_FETCH_HEADERS } from './constants'
import ProgramDates from "./programDates"
import { log, makeLookup, displayDateRange } from "./utils"

const emptyForm = {
  camp_id: null,
  camp_program_id: null,
  full_program: true,
  start_date: null,
  end_date: null,
}

const AddCampModal = (props) => {
  const { currentChildId, refreshUser, camps, setNewCampName, setNewCampProgramName, setNewCampProgramCampId,
    selectedCampId, setSelectedCampId, selectedCampProgramId, setSelectedCampProgramId } = props

  const [show, setShow] = useState(false)
  const [formData, setFormData] = useState(emptyForm)
  const [selectedProgram, setSelectedProgram] = useState(null)
  const [minDate, setMinDate] = useState(null)
  const [maxDate, setMaxDate] = useState(null)
  const [selectedDates, setSelectedDates] = useState(null)
  const [errors, setErrors] = useState([])

  const resetForm = () => {
    setFormData(emptyForm)
    setMinDate(null)
    setMaxDate(null)
    setSelectedDates(null)
    setErrors([])
  }

  const updateSelectedDates = (e) => {
    setSelectedDates(e.target.value)
    const dates = e.target.value.split('/')
    setFormData({
      ...formData,
      "start_date": dates[0],
      "end_date": dates[1],  
    })
  }

  const campOptions = useMemo(() => {
    return Object.values(camps).map(camp => {
      return {
        label: camp.camp_name,
        value: camp.camp_id,
      }
    })
  }, [camps])

  const campOptionLookup = useMemo(() => {
    return makeLookup(campOptions, "value")
  }, [campOptions])

  useEffect(() => {
    if (selectedCampId !== 0) {
      setFormData({
        ...formData,
        "camp_id": selectedCampId,
      })
      setSelectedCampId(0)
    }
  }, [selectedCampId, campOptions, setSelectedCampId])


  const campProgramOptions = useMemo(() => {
    if (formData.camp_id === null) {
      return []
    }
    const programs = camps[formData.camp_id?.toString()]?.programs ?? []
    return programs.map(program => {
      return {
        label: program.camp_program_name + ': ' + displayDateRange(program.start_date, program.end_date),
        value: program.camp_program_id,
      }
    })
  }, [camps, formData.camp_id])
    
  const campProgramOptionLookup = useMemo(() => {
    return makeLookup(campProgramOptions, "value")
  }, [campProgramOptions])

  useEffect(() => {
    if (selectedCampProgramId !== 0) {
      const program = camps[formData.camp_id?.toString()].programs.find(program => program.camp_program_id == selectedCampProgramId) ?? null
      if (program === null) return
      setSelectedProgram(program)
      setFormData({
        ...formData,
        "camp_program_id": selectedCampProgramId,
        "full_program": true,
        "start_date": program.start_date,
        "end_date": program.end_date,
      })
      setMinDate(program.start_date)
      setMaxDate(program.end_date)
      setSelectedCampProgramId(0)
    }
  }, [selectedCampProgramId, campProgramOptions, setSelectedCampProgramId])

  const handleClose = () => setShow(false)
  const handleShow = () => {
    resetForm()
    setShow(true)
  }

  const validateForm = useCallback(() => {
    const errs = [];
    if (formData.camp_id === null) {
      errs.push("Must select a camp.")
    }
    if (formData.camp_program_id === null) {
      errs.push("Must select a camp program.")
    }
    return errs
  }, [formData])


  const handleCampChange = (option) => {
    const firstProgram = camps[option.value]?.programs?.[0]
    const firstProgramId = firstProgram?.camp_program_id ?? null
    setFormData({
      ...formData,
      "camp_id": option.value,
      "camp_program_id": firstProgramId,
      "full_program": true,
      "start_date": firstProgram?.start_date ?? null,
      "end_date": firstProgram?.end_date ?? null,  
    })
    setMinDate(firstProgram?.start_date ?? null)
    setMaxDate(firstProgram?.end_date ?? null)
    setSelectedProgram(firstProgram)
  }

  const handleCampProgramChange = (option) => {
    const campProgramId = option.value
    const program = camps[formData.camp_id?.toString()].programs.find(program => program.camp_program_id == campProgramId) ?? null
    setSelectedProgram(program)
    setFormData({
      ...formData,
      "camp_program_id": campProgramId,
      "full_program": true,
      "start_date": program.start_date,
      "end_date": program.end_date,
    })
    setMinDate(program.start_date)
    setMaxDate(program.end_date)
    // setSelectedDates([`${program.start_date}/${program.end_date}`])
  }

  const handleRegistered = (e) => {
    e.preventDefault()
    handleSubmitForm("registered")
  }

  const handleWaitlisted = (e) => {
    e.preventDefault()
    handleSubmitForm("waitlisted")
  }

  const handleInterested = (e) => {
    e.preventDefault()
    handleSubmitForm("interested")
  }

  // add child to selected camp program
  const handleSubmitForm = (registration_status) => {
    const errs = validateForm()
    setErrors(errs)
    if (errs.length > 0) {
      return
    }


    fetch(`/api/add_camp_attendee`, {
      method: "post",
      headers: JSON_FETCH_HEADERS,
      body: JSON.stringify({
        ...formData,
        "child_id": currentChildId,
        "registration_status": registration_status,
      })
    })
    .then((response) => response.json())
    .then(data => {
        if ("error" in data) {
          alert(data["error"])
        } else {
          refreshUser(currentChildId)
          handleClose()          
        }
    })
    .catch(error => console.error("Add child to camp program error:", error))
  }

  const handleCreateCamp = (newCampName) => {
    setNewCampName(newCampName)
  }

  const handleCreateCampProgram = (newCampProgramName) => {
    setNewCampProgramCampId(formData.camp_id)
    setNewCampProgramName(newCampProgramName)
  }
  const handleInputChange = (e) => {
    const { name, value } = e.target
    setFormData({
        ...formData,
        [name]: value
    })
  }

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        Add a Camp to Your Calendar
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Your Child's Camp Program</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {errors.length > 0 && (
            <Alert key="warning" variant="warning">
              {errors.map((err) => (
                <span>
                  {err}
                  <br />
                </span>
              ))}
            </Alert>
          )}
          <Form>
            <Form.Group className="mb-3" controlId="Camp.Name">
              <Form.Label className="program-form-label">Camp Name</Form.Label>
              <CreatableSelect
                key="camp_id"
                name="camp_id"
                options={campOptions}
                value={campOptionLookup[formData.camp_id?.toString()] ?? null}
                onChange={handleCampChange}
                onCreateOption={handleCreateCamp}
                noOptionsMessage={() => "Type to create a new camp..."}
                placeholder="Type camp name or select..."
              />
            </Form.Group>
            {formData.camp_id !== null && (
              <Form.Group className="mb-3" controlId="Camp.Program">
                <Form.Label className="program-form-label">Camp Program</Form.Label>
                {/* {campProgramOptions.length !== 1 ? ( */}
                <CreatableSelect
                  key="camp_program_id"
                  name="camp_program_id"
                  options={campProgramOptions}
                  value={campProgramOptionLookup[formData.camp_program_id?.toString()] ?? null}
                  onChange={handleCampProgramChange}
                  onCreateOption={handleCreateCampProgram}
                  noOptionsMessage={() => "Type to create a new program..."}
                  placeholder="Type program name or select..."
                />
                {/* ) : (
                  <div className="form-hardcoded">{campProgramOptions[0].label}</div>
                )} */}

              </Form.Group>
            )}
            {formData.camp_program_id !== null && selectedProgram && (
              <ProgramDates
                minDate={minDate}
                maxDate={maxDate}
                schedule={selectedProgram.schedule}
                startDate={formData.start_date}
                endDate={formData.end_date}
                handleInputChange={handleInputChange}
                selectedDates={selectedDates}
                updateSelectedDates={updateSelectedDates}
              />
            )}
            <Form.Group className="mb-3" controlId="Camp.Status">
              <Form.Label className="program-form-label"></Form.Label>
              <Row>
                <Col>
                  <Button className="btn-block pr-2" variant="primary" onClick={handleRegistered}>
                    Registered
                  </Button>
                </Col>
                <Col>
                  <Button className="btn-blockpr-2" variant="secondary" onClick={handleWaitlisted}>
                    Waitlisted
                  </Button>
                </Col>
                <Col>
                  <Button className="btn-block" variant="info" onClick={handleInterested}>
                    Interested
                  </Button>
                </Col>
              </Row>
            </Form.Group>
          </Form>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer> */}
      </Modal>
    </>
  )
}

export default AddCampModal
