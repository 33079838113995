import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const LandingPage = () => {
  return (
    <Container fluid>
      <Row>
        <Col className="text-center">
          <div className="mt-4 mb-3">
            <h1>Welcome to CampFriends!</h1>
          </div>
          <p>
            Picking summer camps for your children is hard - but easier with
            friends! CampFriends is a calendar sharing app that lets parents
            coordinate camp schedules so your child doesn't miss out on
            amazing camp experiences with friends.
          </p>
        </Col>
      </Row>
      <Row>
        <Col></Col>
        <Col xs="3">
          <div className="landing-box">
            <img
              className="landing-image"
              src={require("./images/undraw_text_field.png")}
              alt="Add child's info"
            />
            Enter your child's name, grade and school
          </div>
        </Col>
        <Col xs="3">
          <div className="landing-box">
            <img
              className="landing-image"
              src={require("./images/undraw_landing_calendar.png")}
              alt="Add child's camps to calendar"
            />
            Select camps your child is attending (or wants to attend)
          </div>
        </Col>
        <Col xs="3">
          <div className="landing-box">
            <img
              className="landing-image"
              src={require("./images/undraw_true_friends.png")}
              alt="Share with Friends"
            />
            Share your child's calendar with parents and camp buddies
          </div>
        </Col>
        <Col></Col>
      </Row>
    </Container>
  );
};

export default LandingPage;
