import React, { useEffect } from "react"
import { useParams, useNavigate } from 'react-router-dom'
import { useAtom } from "jotai"
import { userStore } from "./store"
import { JSON_FETCH_HEADERS } from './constants'

/*
- send pending_user API request (add to user account initially)
- if logged in, option to request friendship for current child
- if not logged in, store pending request in session then create once account created
*/
const AddPendingFriend = () => {
    const params = useParams()
    const navigate = useNavigate()
    const inviteCode = params.inviteCode
    const [userLoggedIn, setUserLoggedIn] = useAtom(userStore)

    useEffect(() => {
        fetch(`/api/add_pending_from_invite`, {
            method: "post",
            headers: JSON_FETCH_HEADERS,
            body: JSON.stringify({
              "invite_code": inviteCode,
            })
          })
          .then((response) => response.json())
          .then(data => {
              if ("error" in data) {
                alert(data["error"])
              } else {
                if (userLoggedIn.userId) {
                  navigate("/notifications")
                } else {
                  navigate("/")
                }
              }
          })
          .catch(error => console.error("Add from invite code error:", error))
    }, [userLoggedIn.userId, inviteCode])

    return (
        <div>Adding friend...</div>
    )
}

export default AddPendingFriend