import React, { useState, useCallback } from "react"
import { Form, Modal, Button, Alert } from "react-bootstrap"
import { JSON_FETCH_HEADERS } from './constants'

const emptyForm = {
  camp_id: "",
  camp_program_name: "",
  start_date: "",
  end_date: "",
}

const CreateCampProgramModal = (props) => {
  const { newCampProgramCampId, newCampProgramName, setNewCampProgramName, camps, campsVersion, setCampsVersion, setSelectedCampProgramId } = props
  const [formData, setFormData] = useState({
    ...emptyForm,
    camp_id: newCampProgramCampId,
    camp_program_name: newCampProgramName,
  })
  const [errors, setErrors] = useState([])

  const campName = camps[newCampProgramCampId].camp_name

  const resetForm = () => {
    setFormData({
      ...emptyForm,
      camp_id: newCampProgramCampId,
    })
    setErrors([])
  }

  const handleClose = () => {
    resetForm()
    setNewCampProgramName("")
  }

  const validateForm = useCallback(() => {
    const errs = [];
    if (formData.camp_program_name === "") {
      errs.push("Camp program must have a name.")
    }
    if (formData.start_date === "") {
        errs.push("Camp must have a start date.");
    }
    if (formData.end_date === "") {
        errs.push("Camp must have an end date.");
    }

    return errs
  }, [formData])

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setFormData({
      ...formData,
      [name]: value
    })
  }

  const handleSubmitForm = (e) => {
    e.preventDefault()
    const errs = validateForm()
    setErrors(errs)
    if (errs.length > 0) {
      return
    }

    fetch(`/api/admin/create_camp_program`, {
      method: "post",
      headers: JSON_FETCH_HEADERS,
      body: JSON.stringify(formData)
    })
    .then((response) => response.json())
    .then(data => {
      if ("error" in data) {
        alert(data["error"])
      } else {
        // after submitting successfully, trigger a data refresh to include the new camp program
        setCampsVersion(campsVersion + 1)
        setSelectedCampProgramId(parseInt(data["camp_program_id"], 10))
        handleClose()
      }
    })
    .catch(error => console.error("Create camp program error:", error))
  }

  const handleClear = (e) => {
    e.preventDefault()
    resetForm()
  }

  return (
    <>
      <Modal
        show={true}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Create a Camp Program</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {errors.length > 0 && (
            <Alert key="warning" variant="warning">
              {errors.map((err) => (
                <span>
                  {err}
                  <br />
                </span>
              ))}
            </Alert>
          )}
          <Form>
            <Form.Group className="mb-3" controlId="CampProgram.Camp">
              <Form.Label>Camp</Form.Label>
              <div><i>{campName}</i></div>
            </Form.Group>
            <Form.Group className="mb-3" controlId="CampProgram.Name">
              <Form.Label>Camp Program Name</Form.Label>
              <Form.Control
                type="text"
                name="camp_program_name"
                value={formData.camp_program_name}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="CampProgram.StartDate">
              <Form.Label>Camp Start Date</Form.Label>
              <Form.Control
                type="date"
                name="start_date"
                value={formData.start_date}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="CampProgram.EndDate">
              <Form.Label>Camp End Date</Form.Label>
              <Form.Control
                type="date"
                name="end_date"
                value={formData.end_date}
                onChange={handleInputChange}
                required
              />
            </Form.Group>

          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="secondary" onClick={handleClear}>
            Clear
          </Button>
          <Button variant="primary" onClick={handleSubmitForm}>
            Create Program
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default CreateCampProgramModal
