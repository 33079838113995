import React from "react"
import { useAtom } from "jotai"
import { userStore, accountStore, schoolsAtom } from "./store"
import { Button } from "react-bootstrap"


const MyAccount = () => {
    const [userLoggedIn, setUserLoggedIn] = useAtom(userStore)
    const [accountData, setAccountData] = useAtom(accountStore)
    const [schools, setSchools] = useAtom(schoolsAtom)
  
    const showAddChild = () => {
        setAccountData({ ...accountData, showAddChildModal: true })
    }
    const editChild = (child) => {
        setAccountData({ ...accountData, editChild: child })
    }

    return (
        <div>
            <h2>My Account</h2>
            <div>
                <table>
                {userLoggedIn.children.map(child => {
                    const school = Object.values(schools).filter(s => s.school_id === child.school_id)[0] ?? null
                    const schoolName = school ? school.school_name : ""
                    return (
                        <tr key={child.child_id}>
                            <td className="p-2">{child.full_name}</td>
                            <td className="p-2">{schoolName}</td>
                            <td className="p-2">
                                <Button variant="primary" onClick={() => editChild(child)}>Edit</Button>
                                </td>
                        </tr>
                    )
                })}
                </table>
                <Button variant="primary" onClick={showAddChild}>Add a Child</Button>
            </div>
        </div>
    )
}

export default MyAccount
